import React, { ChangeEvent } from "react";
import { Modal, Container, Button, Form, Spinner, Nav } from "react-bootstrap";
import { SingleDropdownListWithoutSearchBoot } from "../../Common/Components/SingleDropdownListWithoutSearchBoot";
import { MdAssignmentAdd } from "react-icons/md";
import ReportViewer from "./ReportViewer";
import DatePicker from "react-datepicker";
import { CustomerService } from "../../Common/Services/CustomerService";
import moment from "moment-timezone";
import { Utils } from "../../Common/Utilis";
import { resolve, reject } from "q";
import { ScheduledReportSlideout } from "./ScheduleReportSlideout";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast, ToastContainer } from "react-toastify";
import { ReportsService } from "../../Common/Services/ReportsService";
import { VendorService } from "../../Common/Services/VendorService";
import { DropdownMultiSelect } from "../../Common/Components/DropdownMultiSelect";
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime =
  storage === null ? "America/Chicago" : (storage.currentUtcTime as any);
let tenantID = storage === null ? 0 : (storage.tenantID as any);

export class DetailedReport extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.getmyReportDetails = this.getmyReportDetails.bind(this);
this.runReport=this.runReport.bind(this);
    this.state = {
      urlParameter: "",
      loading: false,
      reportId: this.props.reportId,
      reportName: this.props.ReportName,
      reportGroup:this.props.reportGroup,
      id: this.props.id,
      iframeUrl: "",
      ispopupshow: false,
      MyReportName: "",
      isOpen: false,
      selectedModalOption:"",
      selectedcolumnType:"",
      showPopup : false,
      filter:[],
      formData: {
        selectedcolumn: "All",
        reportselected:this.props.ReportName,
        reportselectedid:this.props.reportId,
        filterValue: "",
        startDate: null,
        endDate: null,
        excludes:[],
      },
      modaloptions: ["Contains", "Exact match", "Equals", "Greater than", "Less than"],
      orders: [],
      ExcludOptions: [
        { value: "CustomerName", label: "Customer Name" },
        { value: "CustomerCode", label: "Customer Code" },
        { value: "CustomerPONo.", label: "Customer PO No." },
        { value: "OrderNo", label: "Order No." },
        { value: "PartNo", label: "Part No." },
        { value: "PartDescription", label: "Part Description" },
      ],
ReportVersion :[],


      selectOptions: [
        // {
        //   id: "All",
        //   name: "All",
        // },
        // {
        //   id: this.props.reportId === 5 ? "Vendor Name" : "Customer Name",
        //   name: this.props.reportId === 5 ? "Vendor Name" : "Customer Name",
        // },
        // {
        //   id: this.props.reportId === 5 ? "Vendor Code" : "Customer Code",
        //   name: this.props.reportId === 5 ? "Vendor Code" : "Customer Code",
        // },
      ],
      options: [
        "Part No.",
        "Part description",
        "Qty",
        "Unit price",
        "Total price",
        "Job No.",
        "Customer code",
      ],
      filterOptions: [],
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {

    this.getmyReportDetails();
    this.getSubReports();
    this.GetReportFilterSetting();
    // debugger;

  }

  GetReportFilterSetting = () => {

    ReportsService.GetReportFilterSetting(this.props.reportId)
    .then(async (result: any | null) => {
      if (result !== null) {

   let selectOptions = result
   .map((item) => ({
     id: item.columnDisplayName,
     name: item.columnDisplayName,
     type:item.columnType
   }));
   selectOptions = [{ id: "All", name: "All", type: "all" }, ...selectOptions];

   this.setState({ selectOptions });
      }
      resolve();

    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    });

  };

  getSubReports = () => {

    debugger;
    
    if(this.props.reportGroup!=undefined)
    {
    ReportsService.GetSubReports(this.props.reportGroup)
      .then(async (result: any | null) => {
debugger;
        if (result !== null) {

        
          let selectOptions = result
          .map((item) => ({
            id: item.reportid,
            name: item.reportName,
          }));

          this.setState({ ReportVersion: selectOptions });


        }
        resolve();

      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
    }
  };


  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {

      this.getmyReportDetails(); // Modal opened, so fetch details

    }
    else {
      // Re-enable background scrolling when modal closes
      document.body.style.overflow = 'auto';
    }
  }

  componentWillUnmount() {
    // Ensure scrolling is re-enabled when component unmounts
    document.body.style.overflow = 'auto';
  }
  getmyReportDetails = () => {
    // debugger;

    let formData = this.state.formData;
    let Defaultselected: any;
    let IsCustmerName: any;
    ReportsService.GetMyReportDetails(this.props.id)
      .then(async (result: any | null) => {
        if (result !== null) {
          // debugger;
          result.myReportDetails.forEach((element) => {
            if (element.controlName == "filtercolumn") {
              formData.selectedcolumn = element.controlValue;

              if (element.controlValue == "Customer Name") {
                IsCustmerName = "Yes";
              }
            }

            if (element.controlName == "filtervalue") {
              if (IsCustmerName == "Yes") {
                formData.selectedcolumn = "Customer Name";
                this.GetCustomer();
                //formData.filterValue = element.controlValue;
                //  this.state.formData.selectedcolumn= element.controlValue;
                //let formData = this.state.formData;
                // formData.selectedcolumn = element.controlValue;
                Defaultselected = [{ name: element.controlValue }];
                formData.filterValue = element.controlValue;
                console.log("yoooo", Defaultselected);
                this.handleInputChange("filterValue");
              } else {
                formData.filterValue = element.controlValue;
              }
            }
            if (element.controlName == "Version") {
              formData.reportselected = element.controlValue;
            }
            if (element.controlName == "Datefrom") {
              formData.startDate = element.controlValue;
            }

            if (element.controlName == "Dateto") {
              formData.endDate = element.controlValue;
            }
            this.setState({ formData, Defaultselected });
          });

          if (result.myReportDetails.length == 0) {
            formData.selectedcolumn = "All";
            formData.reportselected=this.props.ReportName;
            formData.filterValue = "";
            formData.startDate = null;
            formData.endDate = null;

            this.setState({ formData });
          }

          //this.setState({ data: result });
        } else {
          let formData = this.state.formData;

          formData.selectedcolumn = "All";
          formData.reportselected="Customer Order Summary";
          formData.filterValue = "";
          formData.startDate = null;
          formData.endDate = null;

          this.setState({ formData });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  handleClose = () => {
    this.props.closeSlideout();
    this.setState({ iframeUrl: "" });
  };

  // handleApply = () => {
  //   this.setState({showPopup:false, selectedOne:`${this.state.formData.selectedcolumn}-${this.state.formData.filterValue}`});
  // };

  handleApply = () => {

    
    this.setState((prevState) => ({
      showPopup: false,
      filter: [
        ...prevState.filter,
        {
          fieldName: prevState.formData.selectedcolumn,
          fieldValue: prevState.formData.filterValue,
          fieldRange: prevState.selectedModalOption
        }
      ],
      formData: {
        ...prevState.formData,
        selectedcolumn:  this.state.formData.selectedcolumn,
        filterValue: ""
      },
      selectedModalOption: ""
    }));
  };
  


  updateMultiSelectItem = (selectedItem: any) => {  
    const { formData } = this.state;

    const selectedValues = selectedItem.map((item: any) => item.value);
    formData.excludes = selectedValues;
    this.setState({ formData , isStateChanged:true});
  
  };


  handleOptionSelect = (option) => {
    this.setState({ selectedModalOption : option });
  };

  handleSelectedItem = (control: any, id: any) => {
    if (this != undefined) {
      if (control === "Customer" && id.length > 0) {
      
        const { formData, selectOptions } = this.state;
        const updatedFormData = { ...formData, selectedcolumn: id };
      
        let filterOptions = [];
        if (id === "Customer Name") {
          this.GetCustomer();
        } else if (id === "Vendor Name") {
          this.GetVendor();
        }
      
        const matchedOption = selectOptions.find(option => option.name === id);
        const selectedcolumnType = matchedOption ? matchedOption.type : null;
        this.setState({
          formData: updatedFormData,
          filterOptions,
          selectedcolumnType,
          showPopup: false
        });
      }
      
      if(control=="Version")
      {
        
        debugger;
        let formData = this.state.formData;
        let filterRow = this.state.ReportVersion.filter(x => x.id === id);
        formData.reportselected = filterRow[0].name;
       formData.reportselectedid = id;
        this.setState({ formData });

        
      }



    }
  };

  handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  GetCustomer = () => {
    let requestlist: any = {};
    CustomerService.GetCustomerlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("ress", result);
          let selectOptions = result
            .filter((item) => item.status === "Active")
            .map((item) => ({
              id: item.customer_id,
              name: item.company_name,
            }));
          this.setState({ filterOptions: selectOptions });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  GetVendor = () => {
    let requestlist: any = {};
    VendorService.GetVendorlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("vendor", result);
          let selectOptions = result
            .filter((item) => item.status === "Active")
            .map((item) => ({
              id: item.vendor_id,
              name: item.company_name,
            }));
          this.setState({ filterOptions: selectOptions });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleChange = (range) => {
    this.setState({
      formData: {
        ...this.state.formData,
        startDate: range[0],
        endDate: range[1],
      },
    });
  };

  UpdateReport = () => {
    if (this.props.id == undefined) {
      this.setState({
        ispopupshow: true,
        reportId: this.props.reportid,
        reportName: this.props.reportName,
      });
    } else {
      this.MyReportSave("Save");
    }
  };

  MyReportSave = (type : any) => {
    this.setState({ loading: true });
    let request: any = {};

    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);

    const datefrom =
      this.state.formData.startDate === null ||
      this.state.formData.startDate === undefined
        ? firstDayOfMonth
        : this.state.formData.startDate === ""
        ? firstDayOfMonth
        : new Date(this.state.formData.startDate);

    const dateto =
      this.state.formData.endDate === null ||
      this.state.formData.endDate === undefined
        ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
        : this.state.formData.endDate === ""
        ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
        : new Date(this.state.formData.endDate);

    if (datefrom > dateto) {
      toast.error("Invalid date range.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
debugger;
    let formData = { ...this.state.formData };
    formData.id = this.props.id;

    if(this.props.reportGroup=="Customer Orders" ||  this.props.reportGroup=="Vendor Orders")
    {
    formData.Reportid =formData.reportselectedid;
    }
    else
    {

      formData.Reportid = this.props.reportId; 
    }
    formData.ReportName = formData.reportselected;

    let options: any = [];

    let item: any = {};
    
    

    item = {};
    item.id = 0;
    item.Reportparamid = this.props.id;
    item.ControlName = "Version";
    item.ControlValue = formData.reportselected;
    item.ControlType ="";
    item.ControlSection="Version";
    options.push(item);

    item = {};
    item.id = 0;
    item.Reportparamid = this.props.id;
    item.ControlName = "Datefrom";
    item.ControlValue = datefrom;
    item.ControlType ="";
    item.ControlSection="daterange";
    options.push(item);

    item = {};
    item.id = 0;
    item.Reportparamid = this.props.id;
    item.ControlName = "Dateto";
    item.ControlType ="";
    item.ControlSection="daterange";
    item.ControlValue = dateto;
    options.push(item);


debugger;

formData.excludes.forEach(element => {
  item = {};
  item.id = 0;
  item.Reportparamid = this.props.id;
  item.ControlName = "excludes";
  item.ControlType ="";
  item.ControlSection="excludes";
  item.ControlValue = element;
  options.push(item);
});


this.state.filter.forEach(element => {
  debugger;
  item = {};
  item.id = 0;
  item.Reportparamid = this.props.id;
  item.ControlName =element.fieldName;
  item.ControlType =element.fieldRange;
  item.ControlSection="filter";
  item.ControlValue = element.fieldValue;
  options.push(item);
});

if(type=="Run")
{
  formData.RunReportDetails = options;
  request = Object.assign({}, JSON.parse(JSON.stringify(formData)));

  ReportsService.RunMyReport(request)
    .then(async (result: any | null) => {
      if (result !== null) {
debugger;
let id =result.id;

        this.runReport(id);
        // toast.success("Report has been added on My Reports", {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        //   containerId: "OrderSlideout",
        // });

        // this.setState({ ispopupshow: false });
      }  
      resolve();
    })
    .catch((error) => {
      this.setState({ isSpinner: false });
      reject();
    })
    .finally(() => {
      this.setState({ loading: false }); 
    });

}
else
{ 
    formData.MyReportDetails = options;
    request = Object.assign({}, JSON.parse(JSON.stringify(formData)));

    ReportsService.UpdateMyReport(request)
      .then(async (result: any | null) => {
        if (result !== null) {
          toast.success("Report has been added on My Reports", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });

          this.setState({ ispopupshow: false });
        } else {
          toast.success("Report name already exists.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      })
      .finally(() => {
        this.setState({ loading: false }); 
      });
}    
  };


  
  handleExcludeChange = (option) => {
    const { selectedExcludes } = this.state;
    this.setState({
      selectedExcludes: selectedExcludes.includes(option)
        ? selectedExcludes.filter((item) => item !== option)
        : [...selectedExcludes, option],
    });
  };

  MyCancel = () => {
    this.setState({ ispopupshow: false });
  };

  handleChange1 = (e: any, control: any) => {
    //e.preventDefault();

    this.setState({ MyReportName: e.target.value });
  };

  runReport = (id : any) => {
    //alert(this.state.formData.reportselectedid==undefined ? this.props.reportId : this.state.formData.reportselectedid);
    debugger;
    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);

    const datefrom =
      this.state.formData.startDate === null ||
      this.state.formData.startDate === undefined
        ? firstDayOfMonth
        : this.state.formData.startDate === ""
        ? firstDayOfMonth
        : new Date(this.state.formData.startDate);

    const dateto =
      this.state.formData.endDate === null ||
      this.state.formData.endDate === undefined
        ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
        : this.state.formData.endDate === ""
        ? new Date(Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime))
        : new Date(this.state.formData.endDate);

    if (datefrom > dateto) {
      toast.error("Invalid date range.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
debugger;
//let reportid :any=this.props.reportId;
// if(this.state.ReportVersion.length!=0 && this.state.ReportVersion.length!=1 )
// {

let reportid  =this.state.formData.reportselectedid==undefined ? this.props.reportId : this.state.formData.reportselectedid;
      
// }

    

debugger;
// const url= `http://localhost:56170/Default.aspx?id=${id}&reportid=${
   //const url =  `https://vaibhavchugh.com/Default.aspx?id=${id}&reportid=${
  const url = `https://website20241103092853.azurewebsites.net/Default.aspx?id=${id}&reportid=${
      reportid
    }&tenantid=${tenantID}&Datefrom=${moment(datefrom).format(
      "MM/DD/YY"
    )}&Dateto=${moment(dateto).format("MM/DD/YY")}&SearchType=${
      this.state.formData.selectedcolumn
    }&SearchValue=${this.state.formData.filterValue}`;

//alert(url);
    const FormData = {
      ...this.state.formData,
      excludes: this.state.selectedExcludes,
      filter: this.state.filter
    };

    console.log("formdata",FormData);
    this.setState({ iframeUrl: url });

    //const url :any =  Showing {from} to {to} of {size} entries;

   // console.log("form data", FormData);
  };

  handleChangeBuyer = (event: any) => {
    console.log("event", event);
    let formData = { ...this.state.formData };
    if (event.length > 0) {
      formData.filterValue = event[0].name;
    }
    this.setState({ formData: formData });
  };

  render() {
    console.log(this.state.selectOptions.lenghth);
    const { iframeUrl } = this.state;
    // this.getmyReportDetails();
    const cdate = new Date(
      Utils.convertUtcToTimezoneFormat(new Date(), currentUtcTime)
    );
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);
    return (
   

      <section>
        <div
          className="details-drawer"
          style={{
            minWidth: "75%",
            width: "992px",
            maxWidth: "100%",
          }}
        >
          <div
            className="back-drop1  viewer-panel1 opacity "
            style={{ minWidth: "25%", maxWidth: "100%", height: "100vh" }}
            onClick={() => this.handleClose()}
          ></div>

          <Container
            fluid
            // ref={this.parentScroll}
            className="body-sec viewer-panel back-drop p-2"
            style={{
              minWidth: "99.5%",
              width: "792px",
              maxWidth: "100%",
              minHeight: "100%",
              overflowY: "auto",
            
            }}
          >
            <div className="page-heading underline d-flex  ">
              {this.props.ReportName}
              <div className="d-flex flex-row ml-auto">
                <Button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={this.handleOpen}
                >
                  Schedule
                </Button>
                {/* <Button
                  type="button"
                  className="btn btn-primary cross mx-3"
                  onClick={this.handleOpen}
                >
                  Export
                </Button> */}

                <button
                  type="button"
                  className="btn btn-primary cross"
                  onClick={() => this.handleClose()}
                  style={{ marginLeft: "10px" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="row g-3 p-3" style={{ height: "83vh" }}>
              <div
                className="col-md-3 border border-grey"
                style={{
                  height: "100%",
                  backgroundColor: "#21252908",
                 // overflowY: "auto",
                  position: "relative",
                  borderRadius: "8px",
                  paddingTop: "50px",
                  paddingBottom: "100px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "50px",
                    backgroundColor: "var(--bs-light)",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    borderBottom: "1px solid #164DA0",
                  }}
                >
                  Filter Options
                </div>

{this.state.ReportVersion.length!=0 && this.state.ReportVersion.length!=1 &&
                <div
                className="col-md-12" >
                  <Form.Group
                    controlId="reportversion"
                     style={{ marginTop: "20px" }}
                  >
                    <Form.Label className="font-semibold">Version</Form.Label>
                    <div style={{ width: "100%" }}>
                      <SingleDropdownListWithoutSearchBoot
                        itemList={this.state.ReportVersion}
                        handleSelectedItem={this.handleSelectedItem.bind(
                          this,
                          "Version"
                        )}
                        defaultItem={this.state.formData.reportselected}
                        defaultText={"Select Filter"}
                        defaultName={this.state.formData.reportselected}
                        controlID="1"
                        id={"ddlselected"}
                      />
                    </div>

                  </Form.Group>
                  </div>
  }
                  <div
                className="col-md-12" >

<Form.Group controlId="daterange my-2" style={{ width: "100%",marginTop:'10px' }}>
                    <Form.Label className="font-semibold">
                      Date Range
                    </Form.Label>
                    <div className="date-picker calendar-picker d-flex align-items-center">
                      <DatePicker
                        id="startDate"
                        selected={
                          this.state.formData.startDate === null ||
                          this.state.formData.startDate === undefined
                            ? firstDayOfMonth
                            : this.state.formData.startDate === ""
                            ? firstDayOfMonth
                            : new Date(this.state.formData.startDate)
                        }
                        onChange={(date) =>
                          this.handleInputChange("startDate")(date)
                        }
                        tabIndex={this.state.tabEnable}
                        className="form-control px-0 text-center"
                        placeholderText={"MM/DD/YY"}
                        style={{ width: "30%" }}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                      />
                      <span className="mx-2">-</span>
                      <DatePicker
                        id="endDate"
                        selected={
                          this.state.formData.endDate === null ||
                          this.state.formData.endDate === undefined
                            ? new Date(
                                Utils.convertUtcToTimezoneFormat(
                                  new Date(),
                                  currentUtcTime
                                )
                              )
                            : this.state.formData.endDate === ""
                            ? new Date(
                                Utils.convertUtcToTimezoneFormat(
                                  new Date(),
                                  currentUtcTime
                                )
                              )
                            : new Date(this.state.formData.endDate)
                        }
                        onChange={(date) =>
                          this.handleInputChange("endDate")(date)
                        }
                        tabIndex={this.state.tabEnable}
                        className="form-control px-0 text-center"
                        placeholderText={"MM/DD/YY"}
                        style={{ width: "30%" }}
                        dateFormat="MM/dd/yy"
                        autoComplete="off"
                      />
                    </div>
                  </Form.Group>




                   {/* <Form.Group controlId="exclude" className="my-3"  style={{ width: "55%" }}>
                    <Form.Label className="font-semibold">Exclude</Form.Label>

                                    <DropdownMultiSelect
                                    // ref={this.multiselecteChild}
                                    data={this.state.ExcludOptions}
                                    label="label"
                                    value="value"
                                    placeHolder="Select"
                                    filtertitle="Search..."
                                    updateMultiSelectItem={this.updateMultiSelectItem}
                                  />

                                </Form.Group> */}


{ this.state.selectOptions.length !=0 && (
                  <Form.Group
                    controlId="customercode"
                    style={{ marginTop: "20px" }}
                  >
                    {this.props.reportGroup=="Customer Orders" &&
                    <Form.Label className="font-semibold">
                      
                      Filter</Form.Label>
  }
    {this.props.reportGroup=="Vendor Orders" &&
                    <Form.Label className="font-semibold">
                      
                      Vendor</Form.Label>
  }
                    <div style={{ width: "55%" }}>
                      <SingleDropdownListWithoutSearchBoot
                        itemList={this.state.selectOptions}
                        handleSelectedItem={this.handleSelectedItem.bind(
                          this,
                          "Customer"
                        )}
                        defaultItem={this.state.formData.selectedcolumn}
                        defaultText={"Select Filter"}
                        defaultName={this.state.formData.selectedcolumn}
                        controlID="1"
                        id={"ddlselected"}
                      />
                    </div>
                  </Form.Group>

                  
                  )}
{ this.state.selectOptions.length !=0 && this.state.formData.selectedcolumn !== "All" && (

<div className="row g-3 pt-4">  
     <div className="col-md-8" >
     <Form.Group
                    controlId="emptyTextField"
                  // style={{ width: "54%" }}
                  >
                    {this.state.formData.selectedcolumn === "Customer Name" ||
                    this.state.formData.selectedcolumn === "Vendor Name" ? (
                      <Typeahead
                        id="selections-example"
                        labelKey="name"
                        onInputChange={(text) => {
                          this.state.formData.filterValue = text;
                        }}
                        onChange={(e) => this.handleChangeBuyer(e)}
                       // className="mb-3 mt-4 w-full"
                        options={this.state.filterOptions}
                        placeholder="Enter Value"
                        selected={this.state.Defaultselected}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Enter value"
                        //className="mb-3 mt-4"
                        value={this.state.formData.filterValue}
                        onChange={this.handleInputChange("filterValue")}
                      />
                    )}
                  </Form.Group>


     </div>
     <div className="col-md-4" >
     <Form.Group>
<Button
                        id="btnSave"
                        variant="primary"
                        type="button"
                        onClick={() => {this.handleApply();}}
                      >
<MdAssignmentAdd    ></MdAssignmentAdd> 

            </Button>

                  </Form.Group>

     </div>
  </div>
)}

                  {/* {this.state.selectedOne && (
  <div
    className="p-2 my-3 position-relative"
    style={{
      background: '#BCC0C3',
      borderRadius: '7px',
      display: 'inline-block'
    }}
  >
    {this.state.selectedOne}
    <button
      onClick={() => this.setState((prevState) => ({
        formData: { ...prevState.formData, selectedColumn: "" },
        selectedOne: ""
      }))}
      style={{
        position: 'absolute',
        top: '-20px',   
        right: '-15px',
        background: 'transparent',
        border: 'none',
        color: '#BCC0C3',
        fontSize: '16px',
        fontWeight: '10px',
        cursor: 'pointer'
      }}
      aria-label="Close"
    >
      &times;
    </button>
  </div>
)} */}

{this.state.filter.length > 0 && this.state.filter.map((item, index) => (
  <div
    key={index}
    className="p-1 my-3 position-relative"
    style={{
      background: '#BCC0C3',
      borderRadius: '7px',
      fontSize:'12px',
      display: 'inline-block',
      marginRight: '8px'
    }}
  >
    {`${item.fieldName}-${item.fieldValue}`}
    <button
      onClick={() => this.setState((prevState) => ({
        filter: prevState.filter.filter((_, i) => i !== index)
      }))}
      style={{
        position: 'absolute',
        top: '-20px',
        right: '-15px',
        background: 'transparent',
        border: 'none',
        color: '#BCC0C3',
        fontSize: '16px',
        cursor: 'pointer'
      }}
      aria-label="Close"
    >
      &times;
    </button>
  </div>
))}



                  {/* <Form.Group
                    controlId="emptyTextField"
                    style={{ width: "54%" }}
                  >
                    {this.state.formData.selectedcolumn === "Customer Name" ||
                    this.state.formData.selectedcolumn === "Vendor Name" ? (
                      <Typeahead
                        id="selections-example"
                        labelKey="name"
                        onInputChange={(text) => {
                          this.state.formData.filterValue = text;
                        }}
                        onChange={(e) => this.handleChangeBuyer(e)}
                        className="mb-3 mt-4 w-full"
                        options={this.state.filterOptions}
                        placeholder="Enter Value"
                        selected={this.state.Defaultselected}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Enter value"
                        className="mb-3 mt-4"
                        value={this.state.formData.filterValue}
                        onChange={this.handleInputChange("filterValue")}
                      />
                    )}
                  </Form.Group> */}

            
                 
                </div>
                <div
                  className="footer-section3"
                  style={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                    <div className="d-flex gap-3 align-items-center">
                      <Button
                        variant="primary"
                        type="button"
                        className="space btn btn-primary btn-discard"
                        onClick={() => this.UpdateReport()}
                      >
                        {this.props.id === undefined
                          ? "Add to My Reports"
                          : "Update"}
                      </Button>
                      <Button
                        id="btnSave"
                        variant="primary"
                        type="button"
                        //onClick={this.runReport}
                        onClick={() => this.MyReportSave("Run")}
                      >
                        Run
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-9"
                style={{ backgroundColor: "#ffffff", height: "100%" }}
              >
                {this.state.loading ? (
                 <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh',
                }}>
                  <span>Generating Report</span>
                  <Spinner />
                </div>
                
                ):(
                  <>
                {iframeUrl && (
                  <div
                    className="border border-grey p-5"
                    style={{ height: "100%", borderRadius: "8px" }}
                  >
                    <ReportViewer reportUrl={iframeUrl} />
                  </div>
                )}
                </>
              )}
              </div>
            </div>

            {this.state.isOpen && (
              <ScheduledReportSlideout
                closeSlideOut={() => {
                  this.setState({ isOpen: false });
                }}
                reportId={this.state.reportId}
                reportName={this.props.ReportName}
              />
            )}


{this.state.ispopupshow && (
                   <div
                   className="d-flex align-items-center pos-fxd2 index-1000"
                   >
                     <div className="react-confirm-alert-overlay confirm-modal undefined">
                       <div className="react-confirm-alert">
                         <div className="react-confirm-alert-body">
                           <div>
                             <div className="d-flex">
                               <h1 style={{ paddingTop: "6px!important" }}> Add to My Reports </h1>
                               <div className="header-popout ml-auto">
                                 <button
                                   type="button"
                                   className="btn wht-bg cross"
                                   onClick={this.MyCancel}
                                 >
                                   <svg
                                     width="24"
                                     height="24"
                                     viewBox="0 0 24 24"
                                     fill="none"
                                     xmlns="http:www.w3.org/2000/svg"
                                   >
                                     <path
                                       d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                                       fill="#84888C"
                                     />
                                   </svg>
                                 </button>
                               </div>
                             </div>
                           </div>
                           <Form.Group controlId="exampleForm.ControlTextarea1">
                             <Form.Label className="font-semibold">
                               {this.state.reportName}
                             </Form.Label>

                             <Form.Control
                               tabIndex={this.state.tabEnable}
                               id="txtTemplateName"
                               type="text"
                               placeholder="Enter Report Name"
                               value={this.state.MyReportName}
                               onChange={(e: any) => this.handleChange1(e, "reportname")}
                               autoComplete="off"
                              // disabled={disable}
                               title=""
                             />
                           </Form.Group>
                           <br></br>
                           <div className="react-confirm-alert-button-group">
                             <button
                               type="button"
                               className="btn"
                               onClick={() => this.MyCancel()}
                             >
                               No
                             </button>

                             <div className="upload-link">
                               <button type="button" className="btn"

                                 onClick={() => this.MyReportSave("Save")}
                                 disabled={this.state.MyReportName.trim() === ''}
                               >
                                 Save
                               </button>

                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 )}

{this.state.showPopup && (
                   <div
                   className="d-flex align-items-center pos-fxd2 index-1000"
                   >
                     <div className="react-confirm-alert-overlay confirm-modal undefined">
                       <div className="react-confirm-alert">
                         <div className="react-confirm-alert-body">
                           <div>
                           <div className="font-semibold text-center my-3" style={{fontSize:'20px'}}>{this.state.formData.selectedcolumn}</div>

                             {/* <div className="d-flex flex-wrap justify-content-center">
                             {this.state.modaloptions.map((option, index) => {
                      const isDisabled =
                        (this.state.selectedcolumnType === "varchar" && ["Greater than", "Less than", "Equals"].includes(option)) ||
                        (this.state.selectedcolumnType === "int" && ["Contains", "Exact match"].includes(option));

                      return (
                        <span
                          key={index}
                          className={`px-4 py-2 mx-1 my-1 ${isDisabled ? 'text-muted' : ''}`}
                          onClick={() => !isDisabled && this.handleOptionSelect(option)}
                          style={{
                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                            background: this.state.selectedModalOption === option && !isDisabled ? '#164DA0' : '#BCC0C3',
                            color: this.state.selectedModalOption === option && !isDisabled ? '#fff' : '#000',
                            opacity: isDisabled ? 0.6 : 1 
                          }}
                        >
                          {option}
                        </span>
                      );
                    })}

                            </div> */}
                           </div>
                           <Form.Group
                    controlId="emptyTextField"
                    style={{ width: "54%" ,marginLeft:"20%"}}
                  >
                    {this.state.formData.selectedcolumn === "Customer Name" ||
                    this.state.formData.selectedcolumn === "Vendor Name" ? (
                      <Typeahead
                        id="selections-example"
                        labelKey="name"
                        onInputChange={(text) => {
                          this.state.formData.filterValue = text;
                        }}
                        onChange={(e) => this.handleChangeBuyer(e)}
                        className="mb-3 mt-4 w-full"
                        options={this.state.filterOptions}
                        placeholder="Enter Value"
                        selected={this.state.Defaultselected}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Enter value"
                        className="mb-3 mt-4"
                        value={this.state.formData.filterValue}
                        onChange={this.handleInputChange("filterValue")}
                      />
                    )}
                  </Form.Group>
                           <br></br>
                    <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                    <div className="d-flex gap-3 align-items-center">
                      <Button
                        variant="primary"
                        type="button"
                        className="space btn-discard"
                        onClick={() => {this.setState({showPopup:false});}}
                      >
                        Discard
                      </Button>
                      <Button
                        id="btnSave"
                        variant="primary"
                        type="button"
                        onClick={() => {this.handleApply();}}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 )}
          </Container>
        </div>
      </section>
    );
  }
}