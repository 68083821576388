import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import * as React from "react";
import { Container, Dropdown, Accordion, Card,Spinner, Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { InvoiceService } from "../../Common/Services/InvoiceService";
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from 'react-bootstrap-table2-paginator';
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
//import {InvoicePaymentslideout}  from './InvoicePaymentslideout';
import {InvoiceEntryslideout}  from '../Purchasing/InvoiceEntryslideout';
import {Receivableslideout}  from './Receivableslideout';
const { SearchBar } = Search;

const DOMPurify = createDOMPurify(window);

export class Register extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.bindfilter = this.bindfilter.bind(this);
    this.state = {
      paymenttype:"",
      footershow: false,
      selectedRows: [],
      invoiceTotal: 0,
      isstatus: true,
      isorderdate: true,
      iscustomername: true,
      iscustomercode: true,
      iscustomerpo: true,
      ispayment:true,
      isdeposit:true,
      htmlItem: [],
      htmlErrorItem: [],
      htmlWarningItem: [],
      searcResult: [],
      invoiceListPaid: [],
      invoiceListPaidPaid: [],
      products:[],
      invoiceId: 0,
      orderIds: [],
      selectedcolumn: "All",
      selectOptions: [
        { id: 'All', name: 'All' },
        { id: 'Ck No', name: 'Ck No' },
        { id: 'Code', name: 'Code' },
        { id: 'Name', name: 'Name' },
        { id: 'Date', name: 'Date' },
        // { id: 'Payment', name: 'Payment' },
        // { id: 'Deposit', name: 'Deposit' },

      ],
      formData:{
        InvoiceIds:[],
        UnApprovedInvoiceIds:[]
      }
    };


  }


  componentDidMount() {
    
    this.getInvoiceListPaid();
  }



  getInvoiceListPaid = () => {
    let requestlist = { ...this.state.requestlist };
    requestlist.isApproved= 1;
    InvoiceService.GetRegister(requestlist)
  .then(async (result: any | null) => {
    
    if (result != null) {
       console.log(result);
      this.setState({ invoiceListPaidPaid: result,selectedRows:[],footershow:false }, () => {
        this.bindfilter();
      });
    }
    resolve();
  })
  .catch((err: any) => {
    toast.error(`Server Error, ${err}`);
    reject();
  });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false, });
  };

  showInvoiceSlideout = () => {
     
    this.setState({ showInvoiceSlideout: true, isSpinner: false,orderIds:[],invoiceId:0 });
  };





  closeSlideOut = () => {
 
    this.getInvoiceListPaid();
    this.setState({ showSlideout: false, isSpinner: false, });
  };

  closeInvoiceSlideOut = () => {
    this.setState({ showInvoiceSlideout: false,showReceivableslideout:false, isSpinner: false, });
    this.getInvoiceListPaid();
  };

  rowEvents = {

    onClick: (e: any, row: any, rowIndex: any) => {
        if (e.target.tagName !== 'BUTTON') 
        {
          
          this.setState({
            showInvoiceSlideout: (row.entryType=="V"),
            showReceivableslideout: (row.entryType=="C"),
             orderid: row.orderId,
             invoiceId: row.invoiceId,
            isSpinner: false,
          });
        }

    },
  };

  bindfilter = () => {
    let selectOptions = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.selectOptions))
    );

    this.setState({ selectOptions: selectOptions });

  };

  handleSelectedItem1 = (control: any, id: any) => {
    let searcResult1 = Object.assign(
      [],
      JSON.parse(JSON.stringify(this.state.searcResult))
    );

   

    let chkckno = id == "Ck No" ? true : false;
    let chkcode = id == "Code" ? true : false;
    let chkname = id == "Name" ? true : false;
    let chkdate = id == "Date" ? true : false;
    let chkpayment = id == "Payment" ? true : false;
    let chkdeposit = id == "Deposit" ? true : false;

    if (id == "All") {
      chkckno = true;
      chkcode = true;
      chkname = true;
      chkdate = true;
      chkpayment=true;
      chkdeposit=true;
    }


    this.setState({
      searcResult: [], selectedcolumn: id, isckno: chkckno, isdate: chkdate,
      isname: chkname, iscode: chkcode,isPayment:chkpayment,isdeposit:chkdeposit
    },
      () => {
        this.setState({ searcResult: searcResult1 });

      }


    );

  };

  




  


  genratePDFPrintPreview = (checkno,series,ckdate,bankid) => {
 

    let invoiceListPaid = this.state.invoiceListPaid;
  
    if (invoiceListPaid.length > 0) {
        let request: any = {};
        
        request.bankid = bankid;
        request.CheckType =  "I";
        request.pvrno = 0;
        request.CheckNo = checkno;
        request.Checkdate =ckdate;
        request.Downloadcopy = "";
        request.Series =series;
        request.isCopyCheck = "Not";
        request.browserName = "Chrome";
        request.vid = 0;
        //request.aid = 0;
        request.Uniquenos = "";
        InvoiceService.PrintActualCheck(request)
            .then(async (result: any | null) => {
              
                this.setState({IsPrinted:true});
                resolve();
            })
            .catch((error) => {
                toast.error("Something went wrong. " + error, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }
  
  };

  moreOptions = (eventKey: any) => {
    if (eventKey == "Print") {
      this.printItem();
    }
  };

  printItem = () => {
 
    let request: any = {};
    request.isApproved= 1; 
    request.search = $('#search-bar-0').val();
    this.setState({ isSpinner:true});
    
    InvoiceService.DownloadRegisterPDF(request)
      .then(async (result: any | null) => {
        this.setState({isSpinner:false, });
        resolve();
      })
      .catch((error) => {
        reject();
        this.setState({ isSpinner:false, });
      });
  };


  printConfirmation(checkno,series,ckdate,bankid) {
    //this.setState({ confirmAlldisabled: true })
    
    let request: any = {};
    request.CheckNos = checkno;
   
    request.bankid =  bankid;
    request.Pvrno = 0;
    request.series=series;
    InvoiceService.UpdatePrintConfirm(request)
        .then(async (result: any | null) => {
            if (result.success) {
              this.closeSlideOut();
                
              
            }
            resolve();
        })
        .catch((error) => {
            this.setState({ confirmAlldisabled: false });
            toast.error("Something went wrong. " + error, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            reject();
        });
  }
  
  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {

      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      },
      {
        text: '25', value: 25
      },
      {
        text: '50', value: 50
      },

      {
        text: 'All', value: this.state.searcResult.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };




    const invoiceColumnsPaid = [
      {
        dataField: 'payDate',
        text: 'Date',
        sort: true,
        headerStyle: { width: '10%' },
        searchable: this.state.isdate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
               {moment(row.payDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: 'vendorCode',
        text: 'Code',
        sort: true,
        searchable: this.state.iscode,
        headerStyle: { width: '10%' },
      },
       
      {
        dataField: 'vendorName',
        text: 'Name',
        headerStyle: { width: '30%' },
        sort: true,
        searchable: this.state.isname,
      },
      {
        dataField: 'ckno',
        text: 'Check No.',
        headerClasses: 'text-center',
        classes: 'text-center',
        
        sort: true,
        searchable: this.state.isckno,
        headerStyle: { width: '10%' },
        formatter: (cell: any, row: any, rowIndex: any) => {

         
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.ckno }
            </EllipsisWithTooltip>
          );
        },

      },
     
      {
        dataField: 'totalAmount',
        text: 'Payment',
        headerClasses: 'text-end',
          classes: 'text-end pe-4 divred',
          sort: false,
          searchable: false,
          //this.state.ispayment,
        headerStyle: { width: '20%' },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.entryType=='V' ? `-${Utils.currencyFormat(row.payment)}` : null}
            </EllipsisWithTooltip>
          );
        },
      },
     

      
   


      {
        dataField: 'Deposit',
        text: 'Deposit',
        sort: false,
        searchable: false,
        //this.state.isdeposit,
        headerClasses: 'text-end',
          classes: 'text-end pe-4 divgreen',
          headerStyle: { width: '20%' },
        //headerStyle: { width: '300px' },
        formatter: (cell: any, row: any, rowIndex: any) => {


          return (
            <EllipsisWithTooltip placement="bottom">
          
           {row.entryType=='V' ? null  : `+${Utils.currencyFormat(row.deposit)}`}
            </EllipsisWithTooltip>
          );
        },
      },
    ];

  


    const selectOptions = [{
      id: 0, column: 'Vendor PO'
    },
    { id: 1, column: 'Vendor Code' },
    { id: 2, column: 'Vendor Name' },
    { id: 3, column: 'Order Date' },
    { id: 4, column: 'Status' },

    ];


    const expandSplitRow = {
      onlyOneExpanding: true,
      parentClassName: "parent-expand-foo",
      renderer: (row: any, rowIndex: any) => renderPreviousTableChild(row),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div></div>;
        }
        return <div></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span className="chevronExpended">
              <img src={chevronRight} alt="chevron Right" />
            </span>
          );
        }
        return (
          <span className="chevronCollpsed">
            <img src={chevronRight} alt="chevron Right" />
          </span>
        );
      },
    };

    const whiteBgFormatter = (column, colIndex) => {
      return <div className="white-bg"></div>;
    };
    
    const renderPreviousTableChild = (mainrow) => {
      let invoiceListPaidPaidDetails: any = [];
      invoiceListPaidPaidDetails = this.state.invoiceListPaidPaid.filter(
        (x) => x.ckno === mainrow.ckno && x.rowType == 2 && x.vendorCode === mainrow.vendorCode && x.payDate === mainrow.payDate 
      );

      // this.setState({ prevOrderidselected:  mainrow.orderID  });

      const invoiceColumnsPaidAll = [
        {
          dataField: 'invoiceNo',
          text:  'Inv / Ord No.',
          sort: true,
          headerStyle: { width: '100px' },
          searchable: true,
          classes:"expanding-bar btn-link",
        },
        {
          dataField: 'invoiceDate',
          text: 'Invoice / Order Date',
          sort: true,
          classes:"expanding-bar",
          headerStyle: { width: '100px' },
          searchable: true,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {moment(row.invoiceDate).format("MM/DD/YYYY")}
              </EllipsisWithTooltip>
            );
          },
        },
        // {
        //   dataField: 'vendorName',
        //   text: 'Vendor Name',
        //   sort: true,
        //   searchable: this.state.iscustomername,
        // },
        {
          dataField: 'dueDate',
          text: 'Due Date',
          sort: true,
          classes:"expanding-bar",
          headerStyle: { width: '100px' },
          searchable: this.state.isorderdate,
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {moment(row.orderDate).format("MM/DD/YYYY")}
              </EllipsisWithTooltip>
            );
          },
        },      
        {
          dataField: 'accountingPeriod',
          text: 'Accounting Period',
          sort: true,
          searchable: this.state.iscustomername,
          classes:"expanding-bar",
          // headerStyle: { width: '300px' },
        },
        {
          dataField: 'totalAmount',
          text: 'Amount',
          headerClasses: 'text-end',
          classes: 'text-end pe-4 expanding-bar',
          sort: true,
          searchable: false,
          headerStyle: { width: '100px' },
          formatter: (cell: any, row: any, rowIndex: any) => {
            return (
              <EllipsisWithTooltip placement="bottom">
                {Utils.currencyFormat(row.payment)}
              </EllipsisWithTooltip>
            );
          },
        },
       
        
     
  
      ];

      return (
        <>
          <div className='px-4 p-4'>
          <BootstrapTable
            id="tblCOAchild"
            keyField="uniqueNo"
            data={invoiceListPaidPaidDetails}
            columns={invoiceColumnsPaidAll}
            rowEvents={this.rowEvents}
          // selectRow={ selectRow }
          //  onClick={(e) => this.handleRowSelectionCheck(e, rowIndex,row.orderID,"no")}
          />
          </div>
        </>
      );
    };
    const {

      showSlideout,
      showInvoiceSlideout,
      showReceivableslideout,

    } = this.state;



 
  




    return (
      <>
        <React.Fragment>



          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">

            Register
              <div className="action-group d-flex flex-row ml-auto">
                {/* <Button onClick={this.shownewslideout}>Add Customer Order</Button> */}
              </div>
            </div>
            <section className="mt-3">
           
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.invoiceListPaidPaid.filter((x)=> x.rowType==1)}
                    columns={invoiceColumnsPaid}
                    search
                  >
                    {
                      props => (


                        <div >
                          <section className="d-flex flex-wrap gap-3 pb-2">
                            <div className="position-relative">
                              <SearchBar {...props.searchProps} />
                            </div>

                            <div
                            //className="input-group w-auto"
                            >
                              <Form.Label>
                                <SingleDropdownListWithoutSearch

                                  itemList={this.state.selectOptions}
                                  handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                  defaultItem={this.state.selectedcolumn}
                                  defaultText={"Select Filter"}
                                  defaultName={this.state.selectedcolumn}
                                  controlID="1"
                                  id={"ddlselected"}

                                /></Form.Label>
                            </div>

                            <div className="col"  style={{ position:'fixed',right:'50px'}}>

                            
    <div className="action">
                   
                     <Dropdown
                       className="more-action"
                       
                       onSelect={(event: any) => { this.moreOptions(event); }}
                     >


                       <Dropdown.Toggle
                         className="btn-outline-primary btn btn-primary more"
                         id="dropdown-more"
                       >
                         <svg
                           width="20"
                           height="20"
                           viewBox="0 0 20 20"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                         >
                           <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                           <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                           <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                         </svg>
                       </Dropdown.Toggle>
                       <Dropdown.Menu>
                    
                           <Dropdown.Item
                             // className={(    this.state.moreActionFileUpload1[idx].eventKey === "delete") ? "isHide" : ""}
                             eventKey={"Print"}
                             key={1}
                           >
                             {"Print"}
                           </Dropdown.Item>
                        
                     
                       </Dropdown.Menu>
                     </Dropdown>

                  
                  
                 </div>
               
                     
               
    </div>

                          </section>
                          <section className="pb-3">
                            <div className="table-responsive divbottom"  >
                              <div className="ml-auto transactions-list">
                                <BootstrapTable
                                id="tblInvoice"
                                {...props.baseProps}
                                keyField="id"
                                 expandRow={expandSplitRow}



                                 pagination={paginationFactory(options)}

                                />
                              </div>
                            </div>
                          </section>
                        </div>

                      )
                    }
                  </ToolkitProvider>

</section>
             

  
                  {(showInvoiceSlideout &&
                <InvoiceEntryslideout
                  closeSlideOut={this.closeInvoiceSlideOut}
                  orderIds={this.state.orderIds}
                  invoiceId={this.state.invoiceId}
                >


                </InvoiceEntryslideout>
              )}

              {(showReceivableslideout &&
                <Receivableslideout
                  closeSlideOut={this.closeInvoiceSlideOut}
                  orderid={this.state.orderid}
                  orderIds={this.state.orderIds}
                  products={this.state.products}
                  invoiceId={this.state.invoiceId}
                >


                </Receivableslideout>
              )}


          </Container>


          {(this.state.isSpinner || this.state.isSaveSpinner) && (
            <div className="loader-spinner d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="success" />
            </div>
          )}

        </React.Fragment>
      </>
    );
  }
}