import axios from "axios";
import Instense from "./Axios-config";
import { API_ROOT } from "./Api-config";
 import { Utils } from "../Utilis";
export class JobServices {

 

public static SaveJob = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  //request[0].tenantid = tenantID;
  request.tenantid = tenantID;
  const url = `/job/SaveJob`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};
public static UpdateJobData = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  //request[0].tenantid = tenantID;
  request.tenantid = tenantID;
  const url = `/job/UpdateAssignJobs`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};


public static ReorderProcess = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  //request[0].tenantid = tenantID;
  request.tenantid = tenantID;
  const url = `/job/ReorderProcess`;
  return Instense.post(url, request).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DeleteJob = async (
  request:  any
): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : storage.tenantID as any;
  request.tenantId = tenantID;
  const url = `/job/DeleteJob`;
  return Instense.delete(url, { params: request }).then((response) => {
    let result = response.data.result as any;
    return result;
  });
};

public static DownloadJobFile = async (request: any): Promise<any | null> => {

  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  let userName = storage === null ? "" : (storage.userName as any);
  request.tenantId = tenantID;
  request.UserName = userName;
  const url = `/job/GetJobPDF`;
  return Instense.get(url,{params:request,  responseType: "blob", headers: {
    'Accept': '*/*',
  }, } ).then(
    (response: any) => {
      // Check if the response is successful
      if (response.status === 200) {
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        // Open the PDF in a new browser tab
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } 
    }
  );
};


public static GetInventoryData = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const url = `/Inventories/GetInventoryData`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetJobData = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  
  const url = `/Job/GetJobData`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetJoblist = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  
  const url = `/Job/GetJoblist`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};
public static GetJoblistOpen = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  
  const url = `/Job/GetJoblistOpen`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};


public static GetOverviewdata = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  
  const url = `/Job/GetOverviewdata`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};
public static GetJobDataByIdOnly = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  
  const url = `/Job/GetJobDataByIdOnly`;
  return Instense.get(url, { params: request }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};


public static GetJobDataByJobid = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Job/GetJobDataByJobid`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};

public static GetDashboardData = async (request: any): Promise<any | null> => {
  let storage = JSON.parse(localStorage.getItem("storage")!);
  let tenantID = storage === null ? 0 : (storage.tenantID as any);
  
  request.tenantid = tenantID;
  const headers = {
    Authorization: `Bearer ${Utils.GetUserToken()}` 
  };
  const url = `/Job/GetDashboardData`;
  return Instense.get(url, { params: request,headers  }).then((response) => {
   
    let result = response.data.result as any;
    return result;
  });
};
}