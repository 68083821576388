import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import * as React from "react";
import {
  Container,
  Dropdown,
  Accordion,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import BootstrapTable from "react-bootstrap-table-next";
import { resolve, reject } from "q";
import { ToastContainer, toast } from "react-toastify";
import { VendorService } from "../../Common/Services/VendorService";
import { Utils } from "../../Common/Utilis";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import createDOMPurify from "dompurify";
import chevronRight from "../../Common/Assets/Images/icons/bx-chevron-right.svg";
import moment from "moment";
import { JobTrackerServices } from "../../Common/Services/JobTrackerServices";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { JobCreations } from "../JobCreations";
import { NCRSlideout } from "../Job/NCRSlideout";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ReportsService } from "../../Common/Services/ReportsService";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import { ReportsSlideout } from "./ReportSlideout";
const { SearchBar } = Search;
import { ScheduledReportSlideout } from "./ScheduleReportSlideout";

import "react-tabs/style/react-tabs.css";

import { DetailedReport } from "./DetailedReport";
const DOMPurify = createDOMPurify(window);

export class Reports extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      Schdata: [],
      taboption: "first",
      showScheduleReport: false,
      selectOptions: [
        {
          id: "All",
          name: "All",
        },
        { id: "Report No", name: "Report No" },
        { id: "Report Type", name: "Report Type" },
        { id: "Report Name", name: "Report Name" },
      ],
    };
  }

  componentDidMount() {
    this.getmyReports();
    this.getScheduledReports();
  }
  getmyReports = () => {
    debugger;

    ReportsService.GetMyReport()
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {
          this.setState({ data: result });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  getScheduledReports = () => {
    ReportsService.GetMyScheduledReports()
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {
          this.setState({ Schdata: result });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  shownewslideout = () => {
    this.setState({ showSlideout: true });
  };

  showslideout = () => {
    this.setState({ showSlideout: true, isSpinner: false });
  };

  closeSlideOut1 = () => {
    this.setState({ showSlideout: false });
    this.getmyReports();
  };
  closeSlideOut = () => {
    this.setState({ showDetailedReport: false, showSlideout: false });
    this.getmyReports();
  };

  MyReportDeleteConfirmation = (id, Reportid, ReportName, type) => {
    let msg = type === "schedulereports" 
    ? "Are you sure you want to delete this scheduled report?" 
    : "Are you sure you want to delete this report?";

    confirmAlert({
      title: "Delete Report",
      message: msg,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (type === "reports") {
              this.MyReportDelete(id, Reportid, ReportName);
            } else if (type === "schedulereports") {
              this.MyScheduleReportDelete(id, Reportid, ReportName);
            }
          },
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  MyScheduleReportDelete = (id, Reportid, ReportName) => {
    debugger;
    let saveRequest: any = {};
    saveRequest.id = id;
    saveRequest.reportid = Reportid;
    saveRequest.reportName = ReportName;
    console.log("delete",saveRequest);
    ReportsService.DeleteMyScheduleReport(saveRequest)
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {
          toast.success("Scheduled Report has been deleted", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });

          this.getScheduledReports();
        } else {
          toast.success("Error in deleting report.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  MyReportDelete = (id, Reportid, ReportName) => {
    debugger;
    let saveRequest: any = {};
    saveRequest.id = id;
    saveRequest.Reportid = Reportid;
    saveRequest.ReportName = ReportName;
    console.log("delete1",saveRequest);

    ReportsService.DeleteMyReport(saveRequest)
      .then(async (result: any | null) => {
        debugger;
        if (result !== null) {
          toast.success("Report has been successfully deleted", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });

          this.getmyReports();
        } else {
          toast.success("Error in deleting report.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "OrderSlideout",
          });
        }
        resolve();
      })
      .catch((error) => {
        this.setState({ isSpinner: false });
        reject();
      });
  };

  rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      debugger;
      this.setState({
        showDetailedReport: true,
        id: row.id,
        reportId: row.reportId,
        reportName: row.reportName,
        reportGroup: row.reportGroup,
      });
    },
  };

  RowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      debugger;
      this.setState({
        showScheduleReport: true,
        id: row.id,
        reportId: row.reportid,
        reportName: row.reportName,
      });
    },
  };

  // handleSelectRow = (eventKey: any, file, index) => {
  //   if (eventKey === "delete") {

  //     let masg = "";

  //     masg = "Are you sure you want to delete this My Report?";

  //     confirmAlert({
  //       title: "Delete Attachment",

  //       message: masg,
  //       buttons: [
  //         {
  //           label: "Yes",
  //           onClick: () => this.MyReportDelete(file),
  //         },
  //         {
  //           label: "No",
  //           onClick: () => reject(),
  //         },
  //       ],

  //       closeOnEscape: false,
  //       closeOnClickOutside: false,
  //     });
  //   }

  //   }
  // };

  handleSelectRow = (eventKey: any, id, reportid, reportname) => {
    if (eventKey === "delete") {
      let request: any = {};

      let masg = "";

      masg = "Are you sure you want to delete this Report?";

      confirmAlert({
        title: "Delete Report",

        message: masg,
        buttons: [
          {
            label: "Yes",
            onClick: () => this.MyReportDelete(id, reportid, reportname),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],

        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (eventKey === "download") {
    }
  };

  handleSelect = (key: string | null) => {
    this.setState({ taboption: key || "first" });
  };

  render() {
    const customTotal = (from, to, size) => (
      <span className="text-muted">
        Showing {from} to {to} of {size} entries
      </span>
    );
    const options = {
      showTotal: true,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },

        {
          text: "All",
          // value: this.state.searcResult.length
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const columnsHistory = [
      {
        dataField: "myreportName",
        text: "My Report Name",
        sort: true,
        classes: "text-truncate cursor-pointer",
        headerStyle: { width: "15%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
              <div style={{ width: "150px" }}>
                <EllipsisWithTooltip placement="bottom">
                  {row.myreportName}
                </EllipsisWithTooltip>
              </div>
            </div>
          );
        },
      },
  
      {
        dataField: "reportName",
        text: "Report Name",
        sort: true,
        classes: "text-truncate cursor-pointer",
        headerStyle: { width: "10%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
              <div style={{ width: "150px" }}>
                <EllipsisWithTooltip placement="bottom">
                  {row.reportName}
                </EllipsisWithTooltip>
              </div>
            </div>
          );
        },
      },
      {
        dataField: "reportDescription",
        text: "Report Description",
        // classes: "text-center",
        // headerClasses: "text-center",
        sort: true,
        headerStyle: { width: "25%" },
        classes: "text-truncate cursor-pointer",
        //searchable:this.state.description,
        hidden: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
            <div style={{ width: "100%" }}>
              <EllipsisWithTooltip placement="bottom">
                {row.reportDescription}
              </EllipsisWithTooltip>
            </div>
          </div>
             
          );
        },
      },
     {
        dataField: "reportGroup",
        text: "Report Tag",
        sort: true,
        headerStyle: { width: "100px" },
        classes: "cursor-pointer",
        //searchable:this.state.isExplanation,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.reportGroup}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "createdDate",
        text: "Date",
        sort: true,
        classes: "text-center",
        headerStyle: { width: "100px", textAlign: "center" },
        // searchable:this.state.isNCRDate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {moment(row.createdDate).format("MM/DD/YYYY")}
            </EllipsisWithTooltip>
          );
        },
      },

      {
        dataField: "",
        text: "Action",

        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="more-action"
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}
                  onSelect={(event: any) =>
                    this.handleSelectRow(
                      event,
                      row.id,
                      row.reportId,
                      row.reportName,
                      
                    )
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"comments"}
                    onClick={() => {
                      this.setState({
                        showDetailedReport: true,
                        id: row.id,
                        reportId: row.reportId,
                        reportName: row.reportName,
                        reportGroup:row.reportGroup,
                      });
                    }}
                  >
                    Run Report{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={"delete"}
                    onClick={() => {
                      this.MyReportDeleteConfirmation(
                        row.id,
                        row.reportid,
                        row.reportname,
                        "reports"
                      );
                    }}
                  >
                    Delete{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const columnsScheduled = [
      {
        dataField: "reportName",
        text: "Report Name",
        sort: true,
        classes: "text-truncate",
        headerStyle: { width: "10%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
              <div style={{ width: "150px" }}>
                <EllipsisWithTooltip placement="bottom">
                  {row.reportName}
                </EllipsisWithTooltip>
              </div>
            </div>
          );
        },
      },
      {
        dataField: "period",
        text: "Period",
        classes: "text-center",
        headerClasses: "text-center",
        sort: true,
        headerStyle: { width: "100px" },
        searchable: this.state.period,
        hidden: false,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.period}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "firstrundate",
        text: "First Run Date",
        sort: true,
        headerStyle: { width: "100px" },
        classes: "text-start",
        headerClasses: "text-start",
        searchable: this.state.firstrundate,
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <EllipsisWithTooltip placement="bottom">
              {row.firstrundate != null && row.firstrundate.length > 0
                ? moment(row.firstrundate).format("MM/DD/YYYY")
                : ""}
            </EllipsisWithTooltip>
          );
        },
      },
      {
        dataField: "schedule",
        text: "Schedule",
        sort: true,
        classes: "text-truncate",
        headerStyle: { width: "10%" },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="d-flex align-items-center txt-bold">
              <div style={{ width: "150px" }}>
                <EllipsisWithTooltip placement="bottom">
                  {row.schedule}
                </EllipsisWithTooltip>
              </div>
            </div>
          );
        },
      },

      {
        dataField: "",
        text: "Action",

        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <div className="btn-group">
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="more-action"
              >
                <Dropdown.Toggle
                  className="btn-outline-primary btn btn-primary-white more"
                  id={"dropdown-more" + rowIndex}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={"delete"}
                    onClick={() => {
                      this.MyReportDeleteConfirmation(
                        row.id,
                        row.reportid,
                        row.reportName,
                        "schedulereports"
                      );
                    }}
                  >
                    Delete{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];

    const { showSlideout } = this.state;

    return (
      <>
        <React.Fragment>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex  ">
              Reports
              <div className="action-group d-flex flex-row ml-auto">
                <Button onClick={this.shownewslideout}>Gallery</Button>
              </div>
            </div>

            <Tab.Container
              transition={false}
              activeKey={this.state.taboption}
              onSelect={this.handleSelect}
            >
              <section className="pb-3">
                <Nav className="nav nav-underline border-bottom">
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="first">
                      My Reports{" "}
                      <span className="badge badge-light">
                        {this.state.data.length || 0}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="second">
                      Scheduled
                      <span className="badge badge-light">
                        {" "}
                        {this.state.Schdata.length || 0}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </section>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={columnsHistory}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          <div>
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                // handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn || "All"}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div>
                        </section>
                        <section className="pb-3">
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                {...props.baseProps}
                                rowEvents={this.rowEvents}
                                pagination={paginationFactory(options)}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ToolkitProvider
                    keyField="id"
                    data={this.state.Schdata}
                    columns={columnsScheduled}
                    search
                  >
                    {(props) => (
                      <div>
                        <section className="d-flex flex-wrap gap-3 pb-2">
                          <div className="position-relative">
                            <SearchBar {...props.searchProps} />
                          </div>

                          <div
                          //className="input-group w-auto"
                          >
                            <Form.Label>
                              <SingleDropdownListWithoutSearch
                                itemList={this.state.selectOptions}
                                // handleSelectedItem={this.handleSelectedItem1.bind(this, 1)}
                                defaultItem={this.state.selectedcolumn}
                                defaultText={"Select Filter"}
                                defaultName={this.state.selectedcolumn || "All"}
                                controlID="1"
                                id={"ddlselected"}
                              />
                            </Form.Label>
                          </div>
                        </section>
                        <section className="pb-3">
                          <div className="table-responsive divbottom">
                            <div className="ml-auto transactions-list">
                              <BootstrapTable
                                id="tblGenerated"
                                {...props.baseProps}
                                keyField="rowId"
                                rowEvents={this.RowEvents}
                                pagination={paginationFactory(options)}
                                defaultSorted={[
                                  {
                                    dataField: "reportDate",
                                    order: "desc",
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </ToolkitProvider>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>

            {(this.state.isSpinner || this.state.isSaveSpinner) && (
              <div className="loader-spinner d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="success" />
              </div>
            )}

            {this.state.showSlideout && (
              <ReportsSlideout
                closeSlideOut={this.closeSlideOut1}
              ></ReportsSlideout>
            )}

            {this.state.showDetailedReport && (
              <DetailedReport
                closeSlideout={() => {
                  this.setState({ showDetailedReport: false });
                }}
                reportId={this.state.reportId}
                ReportName={this.state.reportName}
                id={this.state.id}
                reportGroup={this.state.reportGroup}
              />
            )}
           {this.state.showScheduleReport && (
            <ScheduledReportSlideout
              closeSlideOut={()=>{this.setState({showScheduleReport:false});}}
              reportId={this.state.reportId}
              reportName={this.state.reportName}
              id={this.state.id}
            />
 
           )}
        
          </Container>
        </React.Fragment>
      </>
    );
  }
}
